<template>
    <div class="flex items-center w-full border-b border-black focus:shadow-outline py-2 focus:border-opacity-100 border-opacity-25">
        <select :class="getSelectClass" class="z-10" @change="dropChanged" v-model="selectedValue">                                            
            <option disabled selected hidden>{{ getPlaceHolder }}</option>
            <option v-for="item in getData" :key="item.name + item.id" class="text-black">{{item.name}}</option>
        </select>
        <inline-svg fill="black"  class="hidden md:block w-3 h-3 right-0 m-5 transform rotate-90 absolute z-0" src="/svg/arrow/arrow.svg" />
    </div>
</template>
<script>

export default{
    name: "MyFormDropdown",
    props: {
        data:{
            type: Array,
            default: null
        },
        placeHolder:{
            type: String,
            default: ''
        },
        block:{
            type: String,
            default: ''
        }
    },
    created(){
        this.selectedValue = this.placeHolder
    },
    data(){
        return{
            placeholderChanged: false,
            selectedValue: ''
        }
    },
    methods:{
        dropChanged(){
            this.placeholderChanged = true
            if(this.block == 'location' || this.block == 'vendingType' || this.block == 'propertyType'){
                this.data.forEach(element => {
                    if(element.name == this.selectedValue){
                        this.$emit('dropdown-value', this.block, parseInt(element.id), element.name)
                    }
                });
            }else
                this.$emit('dropdown-value', this.block, this.selectedValue, null)
        }
    },
    computed:{
        getData(){
            return this.data
        },
        getPlaceHolder(){
            return this.placeHolder
        },
        getSelectClass(){
            if(this.placeholderChanged == undefined)
                return 'bg-transparent w-full hover:bg-transparent text-base text-gray placeholder-gray appearance-none cursor-pointer pr-4 py-2 block h-full'

            if(!this.placeholderChanged)
                return 'bg-transparent w-full hover:bg-transparent text-base text-gray placeholder-gray appearance-none cursor-pointer pr-4 py-2 block h-full'
            else
                return 'bg-transparent w-full hover:bg-transparent text-base text-black placeholder-black appearance-none cursor-pointer pr-4 py-2 block h-full'
            
        }
    }
}
</script>
