<template>  
        <div class="absolute top-1/3 w-full z-30 text-sectionTitle menulg:text-hero font-black text-white uppercase font-blackMango text-center">
            
            <transition mode="out-in" name="slide-fade">
                <p class="text-hero lg:text-titleSubPage md:text-titleSubPage tracking-wide font-black" :key="cover.text"> {{cover.text}}</p>
            </transition>
            
            <transition mode="out-in" name="slide-fade">
                <p class="tracking-widest font-bold font-blackMango text-baselg lg:text-lg md:text-lg" :key="cover.text"> {{cover.smallText}}</p>
            </transition>
        </div>
</template>

<script>
export default {
    name: "Cover",

    data() {
        return {
            currentCover: 0,
            cover: null,
            interval: null,
            intervalTime: 8000,
            i: 0,
            coversList: null
        }
    },
    methods: {
        resetTimerAnimation() {
            document.getElementById('timer')?.classList?.remove?.("animate")
            setTimeout(() => {
                document.getElementById('timer')?.classList?.add?.("animate");
            }, 10)
        },
        resetInterval() {
            clearInterval(this.interval);
            this.interval = setInterval(this.toNextCover, this.intervalTime);
        },
    },

    created() {
        this.coversList = Object.keys(this.$i18n.t('sellWithUs.cover')).map(key => {
            return this.$i18n.t('sellWithUs.cover')[key];
        })
        this.cover = this.coversList[this.currentCover];
    },
    mounted() {
        this.interval = setInterval(this.toNextCover, this.intervalTime);
        document.getElementById('timer')?.classList?.add?.("animate")
    },
    watch: {
        $route() {
            this.coversList = Object.keys(this.$i18n.t('sellWithUs.cover')).map(key => {
                return this.$i18n.t('sellWithUs.cover')[key];
            })
            this.cover = this.coversList[this.currentCover];
        },
    },
}
</script>

