<template>
    <div class="w-full h-full md:h-screen overscroll-y-none ">
        <div class="overlay-districts">
          <img src="/sell_with_us/cover.jpg" class="h-full w-full object-cover absolute">
        </div>
        <Cover class="fixed z-auto"/>
        <FullPageModal :ctaText="$t('sellWithUs.formIntroduction')">
            <div class="flex justify-center mt-6 text-sectionTitle lg:text-hero font-blackMango text-black uppercase">
                <p class="ml-4 lg:ml-0 mr-4 lg:mr-0 font-bold tracking-widest text-price lg:text-lg" > {{ $t('sellWithUs.aboutProperty') }}</p>
            </div>
            <FormSellWithUs @thankYouMessage="thankyou" :clickedModalClose="cmc" @buttonActivate="cmc = false"/>
            <div class="w-full h-screen-50 mt-6 mb-24 md:mt-24">
                <div class="w-full h-full flex justify-center">
                    <img class="mt-8 w-6/12 md:w-1/6 h-full left-1/12 md:left-2/4 object-cover rounded-full border-14 border-black sm:h-32 sm:border-14 lg:w-1/6 lg:h-full lg:border-14" 
                        src="/img/sellwithus/sellwithus.jpg"
                    >
                </div>
            </div>
            <Footer properties class="h-full"/>
        </FullPageModal>
        <FormModal v-show="workModal" :noclose="true">
            <!-- begin::modal body -->
            <template v-slot:body>
                <ThankYouModal v-show="thankyouModal" @close="closeWorkModal" />
            </template>
            <!-- end::modal body -->
        </FormModal>
    </div>
</template>
<script>
    import FormSellWithUs from "@/components/sellWithUs/form/FormSellWithUs"
    import Cover from "@/components/sellWithUs/Cover"
    import Footer from '@/layout/footer/FooterExtended'
    import FullPageModal from '@/components/modal/FullPageModal';
    import FormModal from '@/components/modal/FormModal'
    import ThankYouModal from '@/components/modal/ThankYouModal'

export default {
    name: "SellWithUs",
    components: {
        Cover,
        FormSellWithUs,
        Footer,
        FullPageModal,
        FormModal,
        ThankYouModal
    },
    data(){
        return{
            workModal: false,
            thankyouModal: false,
            thankYouName: '',
            cmc: false
        }
    },
    async created(){
       this.$emit('show-header')
    },
    methods:{
      thankyou(name){
        this.thankYouName = name;
        this.thankyouModal = true;
        this.workModal = true
      },
      closeWorkModal(){
            this.cmc = true
            this.workModal = false
      },
    }
}
</script>