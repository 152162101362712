<template>
        <form @submit.prevent="processForm" class="md:space-y-2 w-10/12 md:w-2/4 md:transform md:translate-x-1/2 mx-auto md:mx-0" id="messageForm" :key="reloadForm">
            <div class="md:flex w-full md:space-x-3 md:space-x-8 mt-4 md:mt-16">
                


                <div class="w-full flex items-center">
                    <MyFormDropdown @dropdown-value="processDropdownChange" block="vendingType" :data="vendingOptions" :placeHolder="this.$t('vendingTypes.options[0].name')" />
                </div>

                <div class="w-full flex items-center mt-2 md:mt-0">
                    <MyFormDropdown @dropdown-value="processDropdownChange" block="propertyType" :data="propertyTypeOptions" :placeHolder="this.$t('propertyTypes.placeHolder')" />                    
                </div>
                
              
            </div>

            <div class="flex w-full md:space-x-16">
                <div class="w-full mt-2 md:mt-16">
                    <MyFormDropdown @dropdown-value="processDropdownChange" block="location" :data="districtsOptions" :placeHolder="this.$t('location.placeholder')" />
                </div>     
            </div>

            <!-- VER O V MODEL -->
            <div class="w-full">
                <div class="mt-2 md:mt-16">
                    <iTextarea 
                        name="additionalFeatures" 
                        :placeholder="$t('features.placeholder')" 
                        ref="additionalFeatures"
                        id="additionalFeatures" />
                </div>
            </div>

            <div class="w-full">
                <div class="mt-2 md:mt-16 md:flex w-full md:space-x-8">
                    <MyFormDropdown @dropdown-value="processDropdownChange" block="bedrooms" :data="bedroomsOptions" :placeHolder="this.$t('bedrooms.placeholder')" />
                    
                    <MyFormDropdown @dropdown-value="processDropdownChange" class="mt-2 md:mt-0" block="bathrooms" :data="bathroomOptions" :placeHolder="this.$t('bathrooms.placeholder')" />
                </div>
            </div>

            <div class="w-full">
                <div class="md:flex mt-2 md:mt-16 md:space-x-8">
                     <div class="flex items-center w-full">
                        <iText 
                            id="size" 
                            name="size" 
                            :placeholder="$t('size.placeholder')" 
                            ref="size"
                            class="py-2 md:p-0" />
                            <div  class="text-gray h-full border-b border-black border-opacity-25 py-2 md:p-0">
                                <p>{{$t('size.span')}}</p>
                            </div>
                     </div>

                    <div class="flex items-center w-full mt-2 md:mt-0">
                        <iText 
                            id="salePrice" 
                            name="salePrice" 
                            :placeholder="$t('salePrice.placeholder')" 
                            @on-keyPress="formatPrice"
                            ref="salePrice"
                            class="py-2 md:p-0" />
                            <div  class="text-gray h-full border-b border-black border-opacity-25 py-2 md:p-0">
                                <p>{{$t('salePrice.span')}}</p>
                            </div>
                     </div>
                </div>
            </div>

            <div class="w-full">
                <div class="flex mt-2 lg:mt-16 space-x-3 md:space-x-8">
                    <inline-svg fill="black" class="block right-0 mt-8 mb-8 lg:-mr-6" src="/svg/cloudUpload.svg" />
                    <button type="button" class="btn btn-info mt-8 mb-8 font-bold text-baselg" @click="onPickFile">{{ getUploadFileName }}</button>
                    <input
                        type="file"
                        style="display: none;"
                        ref="fileInput"
                        accept="image/*, application/pdf" 
                        @change="onFilePicked"
                        multiple
                        />
                </div>
            </div>

            <div class="md:mt-32">
                <div class="text-sectionTitle lg:text-hero font-blackMango text-black uppercase flex justify-center ">
                    <p class="font-bold tracking-widest text-lg md:mt-28 mb-4 md:mb-8"> {{ $t('sellWithUs.aboutYou') }}</p>
                </div>
                <div class="md:mt-5 md:flex w-full md:space-x-8">
                    <iText id="firstName" name="firstName" :placeholder="$t('homepage.contact.inputs.firstName')"
                        ref="firstName" type="text" v-model="sellWithUsForm.contact.firstName" class="pr-4 py-2 md:p-0" />
                    <iText id="lastName" name="lastName" :placeholder="$t('homepage.contact.inputs.lastName')"
                        ref="lastName" type="text" v-model="sellWithUsForm.contact.lastName" class="pr-4 py-2 md:p-0 mt-2 md:mt-0" />
                </div>

                <div class="mt-2 md:mt-5 md:flex w-full md:space-x-8">
                    <iText id="email" name="email" :placeholder="$t('homepage.contact.inputs.email')" ref="email"
                        type="email" v-model="sellWithUsForm.contact.email" class="pr-4 py-2 md:p-0 md:mt-5"/>
                    <iText class="pr-4 py-2 md:p-0 mt-2 md:mt-5" id="phone" name="phone" :placeholder="$t('homepage.contact.inputs.phone')" ref="phone" type="tel"
                        v-model="sellWithUsForm.contact.phone" />
                </div>

                <iTextarea class="mt-2 md:mt-5" name="message" :placeholder="$t('homepage.contact.inputs.message')" ref="message"
                    v-model="sellWithUsForm.contact.message" id="message" />

                <div class="pb-2">
                    <div id="check1Div">
                        <iCheckbox class="mt-5 h-auto" id="check1" name="terms" :text="$t('homepage.contact.inputs.agree')"
                            :privacy="$t('homepage.contact.inputs.privacy')" />
                    </div>
                    <div id="check2Div">
                        <iCheckbox class="mt-5 h-auto" id="check2" name="terms" :text="$t('homepage.contact.inputs.communications')" />
                    </div>
                </div>
            </div>
            <button 
                v-if="!formDisabled"
                form="messageForm"
                type="submit"
                class="mt-3 bg-black hover:bg-bronze font-medium uppercase text-sm tracking-button inline-flex items-center justify-center relative transition duration-500 ease-in-out text-white w-full py-2 md:py-3">
                <span>{{ $t('buttons.sendMessage') }}</span>
                <div class="absolute right-3 flex justify-center">
                    <inline-svg fill="white" height="15" src="/svg/arrow/arrowGradient.svg" />
                </div>
            </button>
            <button 
                v-else
                :disabled='formDisabled'
                form="messageForm"
                type="submit"
                class="mt-3 bg-gray font-medium uppercase text-sm tracking-button inline-flex items-center justify-center relative transition duration-500 ease-in-out text-white w-full py-2 md:py-3">
                <span >{{ $t('buttons.messageSent') }} ({{writeCountdown(countdown)}})</span>
            </button>
        </form>
</template>
<script>
import iText from '@/components/contact/types/iText';
import iTextarea from '@/components/contact/types/iTextarea';
import iCheckbox from '@/components/contact/types/iCheckbox';
import MyFormDropdown from '@/components/inputs/MyFormDropdown';

// Execution code
import mixinKeys from '@/models/keys/KeyModel'
import mixinDistrict from '@/models/districts/DistrictsModel'
import mixinGeneral from '@/models/general/GeneralModel'
import { buildPayloadPagination } from '@/utils/utils'

    export default {
    name: "FormSellWithUs",
    props: ['clickedModalClose'],
    mixins: [mixinKeys, mixinDistrict, mixinGeneral],
    components: {
            iText,
             iTextarea,
             iCheckbox,
             MyFormDropdown
        },
    data() {
        return {
            target_types: 'types',
            reloadForm: 0,
            formDisabled: false,
            countdown: 5,
            sellWithUsForm:{
                property_info:{
                    vending_type: null,
                    vending_type_name: null,
                    apartment_type: null, 
                    apartment_type_name: null,
                    district_id: null,
                    district_name: null,
                    additional_features: null,
                    bedrooms: null,
                    bathrooms: null,
                    size: null, 
                    sale_price: null,
                    file_upload: null
                },
                contact: {
                    firstnName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                    message: "",
                    receive_comm: "",
                    agent_id: 0,
                }
            },
            uploadFileName:{
                fileUploaded: ''
            }
        }
    },
    async created(){
        try{
            await this.getKeyItems(
                buildPayloadPagination({}, this.buildSearch(), this.target_types)
            )
            await this.getDistricts()
        }catch(error){
            console.log(error)
        }
    },
    computed: {
        getUploadFileName(){
            if(this.uploadFileName.fileUploaded == ''){
                return this.$t('UploadFiles')
            }

            return this.uploadFileName.fileUploaded
        },
      vendingOptions(){
          let options = [
                    { id: this.$t('vendingTypes.options[0].id'), name: this.$t('vendingTypes.options[0].name')},
                    { id: this.$t('vendingTypes.options[1].id'), name: this.$t('vendingTypes.options[1].name')}
            ]
          return options
      },
      propertyTypeOptions(){
          return this.organizeOptionsList(this.$store.state.keysModule.key.types.data)
      },
      districtsOptions(){
          return this.organizeOptionsList(this.$store.state.districtsModule.districts)
      },
      bathroomOptions(){
          return this.$t('bathroomsOptions.options')
      },
      bedroomsOptions(){
          return this.$t('bedroomsOptions.options')
      }
    },
    methods:{
        writeCountdown(c){
            if(c < 0)
                return 5
            return c
        },
        formatPrice(){
            let n = document.getElementById('salePrice').value

            n = n.replaceAll(',','')
            
            n = n.replaceAll('.','')


            let val = Math.round(Number(n) *100) / 100;
            let parts = val.toString().split(",");

            let num = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
            
            if(n > 1000)
                num = num.replace(',', '.')

            document.getElementById('salePrice').value = num
        },
        onPickFile () {
            this.$refs.fileInput.click()
        },
        onFilePicked (event) {
            const files = event.target.files

            this.sellWithUsForm.property_info.file_upload = files
            
            this.uploadFileName.fileUploaded = files.length + this.$t('FileLength')
        },
        processDropdownChange(block, value, name){
            switch(block){
                case 'vendingType':
                    this.sellWithUsForm.property_info.vending_type = value
                    this.sellWithUsForm.property_info.vending_type_name = name
                    break;
                case 'propertyType':
                    this.sellWithUsForm.property_info.apartment_type = value
                    this.sellWithUsForm.property_info.apartment_type_name = name
                    break;
                case 'location':
                    this.sellWithUsForm.property_info.district_id = value
                    this.sellWithUsForm.property_info.district_name = name
                    break;
                case 'bedrooms':
                    this.sellWithUsForm.property_info.bedrooms = value
                    break;
                case 'bathrooms':
                    this.sellWithUsForm.property_info.bathrooms = value
                    break;
                default:
                    break;
            }
        },
        organizeOptionsList(l){
            let returningOptions = []
            l.forEach(element => {
                returningOptions.push({id: element.id, name: this.$processLanguage(element.name)})
            });
            return returningOptions
        },
        gatherPropertyInfo(){            
            this.sellWithUsForm.property_info.additional_features = document.getElementById('additionalFeatures').value
            this.sellWithUsForm.property_info.size = document.getElementById('size').value
            this.sellWithUsForm.property_info.sale_price = document.getElementById('salePrice').value
        },
        gatherContactInfo(){
            this.sellWithUsForm.contact.firstnName = document.getElementById('firstName').value
            this.sellWithUsForm.contact.lastName = document.getElementById('lastName').value
            this.sellWithUsForm.contact.email = document.getElementById('email').value
            this.sellWithUsForm.contact.phone = document.getElementById('phone').value
            this.sellWithUsForm.contact.message = document.getElementById('message').value
            this.sellWithUsForm.contact.receive_comm = document.getElementById('check2').checked
        },
        async processForm(){          
            this.formDisabled = true
              
            this.gatherContactInfo()
            this.gatherPropertyInfo()
            
            //if(this.validateForm())
            //    return
            
            
            await this.sellWithUsReq({data: this.sellWithUsForm, block: 'formSellWithUs'});
            this.$emit('thankYouMessage', this.sellWithUsForm.contact.firstnName );

            // Formulário validado
            // Limpar dados
            this.sellWithUsForm.contact.lastName = ''
            this.sellWithUsForm.contact.email = ''
            this.sellWithUsForm.contact.phone = ''
            this.reloadForm++
        },
        validateForm(){
            let invalid = false
            if(this.sellWithUsForm.contact.firstnName == ""){
                document.getElementById('firstName').style="border-color: red;";
                invalid = true
            }

            if(this.sellWithUsForm.contact.lastName == ""){
                document.getElementById('lastName').style="border-color: red;";
                invalid = true
            }

            if(this.sellWithUsForm.contact.email == ""){
                document.getElementById('email').style="border-color: red;";
                invalid = true
            }

            if(this.sellWithUsForm.contact.message == ""){
                document.getElementById('message').style="border-color: red;";
                invalid = true
            }

            if(!document.getElementById('check1').checked){
                document.getElementById('check1Div').style="color: red;";
                invalid = true
            }

            return invalid
        },
        buildSearch() {
            return this.search
            ? { query: this.search, fields: this.fieldsToSearch.join(',') }
            : {}
        },
    },
    watch:{
        clickedModalClose(c){
            let parent = this
            if(c == true){
                this.$emit('buttonActivate')
                var timeleft = 10;
                var downloadTimer = setInterval(function(){
                    if(parent.countdown <= 0){
                        parent.formDisabled = false
                        parent.countdown = 5
                        clearInterval(downloadTimer);
                    }
                    parent.countdown = 5 - (10-timeleft)
                    timeleft -= 1;
                }, 1000);
                this.countdown = 5

            }
        }
    }
}
</script>